<template>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h1>Lista pakietów sprzedażowych</h1>
    </div>
    <div class="col-md-1"></div>
  </div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <table id="sales-packages" class="list-table">
        <tr>
          <th style="width: 2%;">ID</th>
          <th style="width: 5%;">Name</th>
          <th style="width: 5%;">Partner</th>
          <th style="width: 2%;">Type</th>
          <th style="width: 2%;">Valid From</th>
          <th style="width: 2%;">Valid To</th>
          <th style="width: 2%;">Akcja</th>
        </tr>

        <template v-for="salesPackage in salesPackageList" v-bind:key="salesPackage.id">
          <tr>
            <td>{{ salesPackage.id }}</td>
            <td>{{ salesPackage.name }}</td>
            <td>{{ salesPackage.partner.name }}</td>
            <td>{{ salesPackage.type.type }}</td>
            <td>{{ salesPackage.validFrom }}</td>
            <td>{{ salesPackage.validTo }}</td>
            <td><router-link v-bind:to="'/admin/sales-package/' + salesPackage.id" tag="button">Wyświetl</router-link></td>
          </tr>
        </template>
      </table>
    </div>
    <div class="col-md-1"></div>
  </div>
</template>

<script>
import { SalesPackageService } from '../../../services/admin/SalesPackage/salesPackageService';

export default {
  name: 'SalesPackageList',
  data() {
    return {
      salesPackageList: []
    };
  },
  mounted() {
    this.displaySalesPackageList();
  },
  methods: {
    async displaySalesPackageList() {
      const response = await SalesPackageService.getSalesPackageList();
      this.salesPackageList = response.data.salesPackages.items;
    }
  }
};
</script>

<style scoped>
.list-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even){background-color: #f2f2f2;}

.list-table tr:hover {background-color: #ddd;}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
